
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RouteNames } from '@/enums/routes/RouteNames';
import { UserRightsMixin } from '@/mixins/UserRights';
import { mixins } from 'vue-class-component';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import HelpPage from '@/components/global/HelpPage.vue';

@Component({
    name: 'SettingsTopBar',
    components: { HelpPage },
})
export default class SettingsTopBar extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ required: false }) private pathBefore!: string;
    @Prop({ required: true }) private pathCurrent!: string;
    @Prop({ required: false }) private backRoute!: RouteNames;
    @Prop({ required: false }) private showBackButton!: boolean;
    @Prop({ required: false }) private showSubmitButton!: boolean;
    @Prop({ required: false }) private submitButtonText!: string;
    @Prop({ required: false }) private submitButtonEvent!: EventBusEvents;
    @Prop({ required: false }) private customComponent!: any;
    @Prop({ required: false }) private customComponentData!: any;
    @Prop({ required: false }) private customComponentCurrentItem!: any;
    @Prop({ required: false }) private showCustomComponent!: boolean;
    private get entityName() {
        return this.$route.params.entityName;
    }

    private goBack(): void {
        this.$router.go(-1);
    }
    private submitOnClick(): void {
        EventBus.$emit(this.submitButtonEvent);
    }
}
